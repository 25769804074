import { useState, useEffect, useRef } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { FaCheck } from "react-icons/fa6";
import { FaSortAmountDown } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState("up");

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection);
        return () => {
            window.removeEventListener("scroll", updateScrollDirection);
        }
    }, [scrollDirection]);

    return scrollDirection;
};

const Header = ({ defaultSelection, items, handleSort, handleQuery, page, backButton }) => {
    const [selectedItem, setSelectedItem] = useState(defaultSelection);
    const [search, setSearch] = useState(false);
    const [query, setQuery] = useState("");
    const scrollDirection = useScrollDirection();
    const inputRef = useRef(null);

    const handleMenuItemClick = (item) => {
        setSelectedItem(item);
        handleSort(item);
    };

    const handleSearchClick = () => {
        if (search === false) {
            setSearch(true)
            setTimeout(() => {
                document.getElementById('inputSearch')?.focus();
            }, 100);
        } else setSearch(false);
    };

    const handleSearch = (e) => {
        if (e.key === "return" || e.keyCode === 13) {
            setSearch(false);
        }

        setQuery(e.target.value);
        handleQuery(e.target.value);
    };

    useEffect(() => {
        if (search && inputRef.current) {
            inputRef.current.focus();
        }
    }, [search]);

    var phoneContainer = `h-32 -top-16 -mt-32 ${scrollDirection === "down" ? "opacity-0" : "opacity-100"}`
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        phoneContainer = `h-16 top-0 ${scrollDirection === "down" ? "opacity-0" : "opacity-100"}`
    }

    return (
        <div className={`${phoneContainer} sticky w-screen bg-white/60 dark:bg-spotGray/60 backdrop-blur-md transform-gpu transition-all duration-300`}>
            {page === "listView" ?
                <div>
                    <div className={`absolute bottom-4 right-[65px] flex flex-row px-2 items-center ${search === true ? "w-[calc(100%_-_95px)] rounded-full" : "w-9"} bg-transparent h-9 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 z-50 transition-all duration-300 ease-in-out`}>
                        <IoSearch className="text-xl text-spotGray dark:text-gray-100" onClick={handleSearchClick} />
                        {search === true ?
                            <input
                                ref={inputRef}
                                type="text"
                                id='inputSearch'
                                className="w-full h-full bg-transparent text-sm text-spotGray dark:text-gray-100 pl-2"
                                style={{ outline: 'none' }}
                                placeholder="Search License Plate"
                                onBlur={handleSearchClick}
                                onKeyDown={handleSearch}
                                onChange={handleSearch}
                                value={query} />
                            : null
                        }
                    </div>
                </div>
                : null}
            <Menu as="div" className="inline-block text-left absolute bottom-4 right-4">
                <div className='flex justify-end items-end'>
                    <MenuButton className="inline-flex w-9 h-9 gap-x-1.5 justify-center items-center rounded-md bg-transparent px-2 py-1 text-sm font-semibold text-gray-800 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 ">
                        <FaSortAmountDown className="text-2xl bg-transparent" />
                    </MenuButton>
                </div>
                <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white dark:bg-spotGray dark:border dark:border-gray-300 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                        {items.map((item) => (
                            <MenuItem key={item}>
                                <div
                                    href="#"
                                    onClick={() => handleMenuItemClick(item)}
                                    className="px-4 py-2 text-sm text-gray-800 dark:text-gray-100 flex flex-row gap-2"
                                >
                                    {selectedItem === item ? <FaCheck className="float-right text-spotGray dark:text-gray-100" /> : <FaCheck className="float-right text-transparent" />}
                                    {item}
                                </div>
                            </MenuItem>
                        ))}
                    </div>
                </MenuItems>
            </Menu>
        </div>
    );
}

export default Header;