import React, { useState } from "react";
import "./Ticket.css";
import TimeParked from "./TimeParked";
import { useNavigate } from "react-router-dom";
import { FaTrashCan, FaPrint } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { fetchAllData, postData } from "../utility/fetcher";
import { Toaster, toast } from "sonner";
import { QRCodeSVG } from 'qrcode.react';

const Ticket = ({ violationData, fullName }) => {
    // Initial Data
    const currentUniData = JSON.parse(sessionStorage.getItem("uniData"));
    const baseFee = currentUniData["baseFee"];
    const {
        spotID,
        licensePlate,
        violationType,
        lot,
        spot,
        timeParked,
        coordinates,
        distance,
        vehicleType,
        permitNumber,
        level
    } = violationData;


    // State Definitions
    const [feeInput, setFeeInput] = useState("$25.00");
    const [nameInput, setNameInput] = useState(fullName);
    const [isDarkened, setIsDarkened] = useState(false);
    const [deleteText, setDeleteText] = useState("Delete");
    const [deleteOpacity, setDeleteOpacity] = useState(1);
    const [deleteReason, setDeleteReason] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();

    // Fee Input
    const handleFeeChange = (e) => {
        setFeeInput(e.target.value);
    };

    const getCitationNumber = async () => {
        const url = { "allUsers": "/general/spotUsers" }
        const data = await fetchAllData(url);
        const allUsers = data.allUsers;

        let allCitationIDs = [];
        for (let i in allUsers) {
            const user = allUsers[i];
            if (user.citations) {
                for (let j in user.citations) {
                    allCitationIDs.push(user.citations[j].citationID);
                }
            }
        }
        const nextID = String(Math.max(...allCitationIDs.map(id => parseInt(id, 10)), 0) + 1).padStart(6, '0');
        return nextID;
    }

    const printReceipt = async () => {
        const citationNumber = await getCitationNumber();
        const options = { hour: "numeric", minute: "numeric", hour12: true, timeZone: "UTC" };
        const time = new Date(timeParked).toLocaleTimeString([], options);
        const date = new Date(timeParked).toLocaleDateString();
        const formattedTimeParked = `${date} ${time}`;
        const type = vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1);
        // Encode your URL
        const qrCodeURL = encodeURIComponent(`https://nav.spotparking.app/visitorCitation?citationID=${citationNumber}`);

        // Generate QR code image URL
        const qrCodeImageURL = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeURL}`;

        // Update the receipt content
        const receiptContent = `
            <div style="font-family: sans-serif; line-height: 1.5em; text-align: center;">
                <div style="font-size: 18px; font-weight: bold;">
                ----------------------------<br>
                  PARKING CITATION<br>
                  Citation ID: ${citationNumber}<br>
                ----------------------------
                </div>
                <div style="font-size: 16px; margin: 10px 0;">
                    <strong>License Plate:</strong> ${licensePlate}<br>
                    <strong>Violation:</strong> ${violationType}<br>
                    <strong>Lot:</strong> ${lot}<br>
                    <strong>Spot:</strong> ${spot}<br>
                    <strong>Vehicle Type:</strong> ${type}<br>
                    <strong>Time Parked:</strong> ${formattedTimeParked}<br>
                    <strong>Fee:</strong> ${feeInput}<br>
                    <strong>Employee:</strong> ${nameInput}
                </div>
                <div style="display: flex; justify-content: center; margin: 10px 0;">
                    <img src="${qrCodeImageURL}" alt="QR Code">
                </div>
                <div style="font-size: 14px; font-weight: bold; margin-top: 20px;">
                --------------------------------<br>
                CITATION MUST BE CLEARED WITHIN<br>
                14 CALENDAR DAYS OF ISSUE.<br>
                PARKING VIOLATIONS MAY BE SUBJECT<br>
                TO BEING BOOTED OR TOWED.<br>
                --------------------------------
                </div>
            </div>
            `;

        // Save the original content
        const bodyChildren = Array.from(document.body.children);
        const originalContents = bodyChildren.map(child => child.outerHTML);

        // Create a container for the receipt content
        const receiptDiv = document.createElement('div');
        receiptDiv.innerHTML = receiptContent;

        // Log the receipt content to debug
        console.log('Receipt Content:', receiptDiv.innerHTML);

        // Hide all other elements on the page except for the receipt div
        bodyChildren.forEach(child => {
            if (child !== receiptDiv) {
                child.style.display = 'none';
            }
        });

        // Append the receipt content to the body
        document.body.appendChild(receiptDiv);

        // Check if the QR code image loads correctly
        const qrImage = receiptDiv.querySelector('img');
        if (qrImage) {
            qrImage.onload = () => console.log('QR code image loaded successfully');
            qrImage.onerror = () => console.error('Error loading QR code image');
        }

        // Print the content
        setTimeout(() => {
            window.print();
        }, 100);

        //TODO FIX THIS SO IT'S NOT GAY
        // Use matchMedia to detect when the print dialog is closed
        const mediaQueryList = window.matchMedia('print');
        const handleMediaQueryChange = (event) => {
            if (!event.matches) {
                // The print dialog is closed, restore the original content
                setTimeout(() => {
                    document.body.innerHTML = originalContents.join('');
                    setIsDarkened(false)
                    window.location.reload();
                    mediaQueryList.removeEventListener('change', handleMediaQueryChange);
                }, 5000);
            }
        };

        mediaQueryList.addEventListener('change', handleMediaQueryChange);
    };

    const handlePrint = () => {
        setIsDarkened(true);
        setTimeout(() => {
            printReceipt();
        }, 1000);
    };

    const orgID = currentUniData["orgID"];
    const handleButtonClick = async () => {
        setIsChecked(true);

        if (orgID === undefined) {
            setIsChecked(false)
            toast.error("Error saving data. Contact support.");
            return;
        }

        try {
            // Push to live data
            const path = `/${orgID}/spots`;
            const response = await postData(path, jsonData);
            console.log(response.message)
        } catch (error) {
            setIsChecked(false)
            toast.error("Error saving data. Contact support.");
            return;
        }

        // Save data to history
        const historyData = JSON.parse(sessionStorage.getItem("violationHistory"));
        var historyArray = historyData.length === 0 ? [] : historyData.find(item => item.licensePlate === licensePlate)?.History || [];

        let newFeeInput = "";
        if (feeInput.includes("$")) {
            newFeeInput = feeInput.substring(1);
        }

        const newPush = {
            "spotID": spotID,
            "employee": nameInput,
            "lot": lot,
            "permitType": "", //TODO: Add permit type to violationData
            "spot": spot,
            "stolen": "false", //TODO: Add stolen to violationData
            "ticketWritten": "true",
            "ticketWrittenTime": new Date().toISOString(),
            "timeIn": timeParked,
            "timeOut": "",
            "violationType": violationType,
            "fee": newFeeInput,
            "deleteReason": "",
            "level": level
        }

        historyArray.push(newPush);

        const historyBuild = {
            "licensePlate": licensePlate,
            "history": historyArray
        }

        // Push to history
        try {
            console.log("HISTORY Data to save:", historyBuild);
            const path = `/${orgID}/history`;
            const response = await postData(path, historyBuild);
            console.log(response.message)
        } catch (error) {
            setIsChecked(false)
            toast.error("Error saving data. Contact support.");
            return;
        }

        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.licensePlate === licensePlate);
        console.log(foundItem)
        if (foundItem) {
            violationData = violationData.filter(item => item.licensePlate !== licensePlate);
            foundItem.ticketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }

        setIsChecked(false);
        navigate(-1);
    };

    const deleteViolation = async (deleteReason) => {
        // JSON Build
        const newJsonData = {
            "LicensePlate": licensePlate,
            "Coordinates": coordinates,
            "Distance": distance,
            "PermitNumber": permitNumber,
            "Lot": lot,
            "Spot": spot,
            "TicketWritten": false,
            "timeParked": timeParked,
            "VehicleType": vehicleType,
            "ViolationType": "N/A"
        }

        // Perform POST request here
        const path = `/${orgID}/spots`;
        try {
            const response = await postData(path, newJsonData)
            console.log(response.message);
        } catch (error) {
            toast.error("Error saving data");
            return;
        }

        // Save data to history
        const historyData = JSON.parse(sessionStorage.getItem("violationHistory"));
        const historyArray = historyData.find(item => item.licensePlate === licensePlate)?.History || [];
        const newPush = {
            "employee": nameInput,
            "lot": lot,
            "permitType": "", //TODO: Add permit type to violationData
            "spot": spot,
            "stolen": "false", //TODO: Add stolen to violationData
            "ticketWritten": "false",
            "ticketWrittenTime": "N/A",
            "timeIn": timeParked,
            "timeOut": "",
            "violationType": "N/A",
            "fee": "",
            "deleteReason": deleteReason
        }

        historyArray.push(newPush);
        const historyBuild = {
            "licensePlate": licensePlate,
            "history": historyArray
        }

        try {
            const saveData = async () => {
                console.log("HISTORY Data to save:", historyBuild); //TODO: change this to historyData
                const path = `/${orgID}/history`;
                const response = await postData(path, historyBuild);
                console.log(response.message)
            };

            (async () => {
                await saveData();
            })();
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Error saving data");
            return;
        }

        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.licensePlate === licensePlate);
        if (foundItem) {
            violationData = violationData.filter(item => item.licensePlate !== licensePlate);
            foundItem.ticketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }
    }

    const handleDelete = async () => {
        setDeleteOpacity(0.5);
        setDeleteText("Deleting...");
        setShowOverlay(true);
    };

    const handleDeleteConfirm = async () => {
        if (deleteReason === "") {
            setDeleteWarning("Please select a reason.");
            return;
        }

        // Save data to history
        setShowOverlay(false);
        deleteViolation(deleteReason);
        window.history.back();
    };

    const handleDeleteCancel = () => {
        setShowOverlay(false);
        setDeleteWarning("");
        setDeleteOpacity(1);
        setDeleteText("Delete");
    };

    const jsonData = {
        "spotID": spotID,
        "licensePlate": licensePlate,
        "coordinates": coordinates,
        "distance": distance,
        "permitNumber": permitNumber,
        "lot": lot,
        "spot": spot,
        "ticketWritten": true,
        "timeParked": timeParked,
        "vehicleType": vehicleType,
        "violationType": violationType,
        "level": level
    }

    var buttonBottom = "bottom-32";
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        buttonBottom = "bottom-28";
    }

    return (
        <div>
            <div className="fcc mt-16 w-screen print:hidden font-rubik">
                <div className="text-spotGray dark:text-gray-100 bg-white dark:bg-spotGray shadow-md rounded-2xl p-4 w-[95%] border border-gray-200 dark:border-gray-600">
                    <h2 className="font-bold text-2xl text-spotGray dark:text-gray-100 text-center">Ticket Information</h2>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="flex flex-row flex-wrap items-start p-1 gap-2">
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Plate #:</p>
                                <p>{licensePlate}</p>
                            </div>
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Parking Violation:</p>
                                <p>{violationType}</p>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-start p-1 gap-2">
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Spot Number:</p>
                                <p>{lot}-{spot}</p>
                            </div>
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Time Parked:</p>
                                <p><TimeParked dateTime={timeParked} /></p>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-start p-1 gap-2">
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Fee:</p>
                                <input
                                    type="text"
                                    value={feeInput}
                                    onChange={handleFeeChange}
                                    onClick={() => setFeeInput("")}
                                    onBlur={() => { feeInput === "" ? setFeeInput(`$${parseFloat(baseFee).toFixed(2)}`) : setFeeInput(`$${parseFloat(feeInput).toFixed(2)}`) }}
                                    onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                                    className="text-sm p-1 rounded-md border mt-2 w-full bg-white text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                                    style={{ marginTop: "4px", width: "100px", height: "20px" }}
                                />
                            </div>
                            <div className="flex flex-col items-start rounded-xl p-2 border grow dark:border-gray-600">
                                <p className="text-lg font-bold">Employee Name:</p>
                                <input
                                    type="text"
                                    value={nameInput}
                                    onChange={(e) => setNameInput(e.target.value)}
                                    onClick={() => setNameInput("")}
                                    onBlur={() => { nameInput === "" ? setNameInput(fullName) : setNameInput(nameInput) }}
                                    onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                                    className="text-sm p-1 rounded-md mt-2 w-full bg-white border text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                                    style={{ marginTop: "4px", width: "150px", height: "20px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`fixed z-50 justify-center frc gap-2 ${buttonBottom} bottom-20 w-[90%] justify-self-center`}>
                    <button
                        className={`rounded-full text-spotGray shadow-lg flex-1 h-[50px] justify-center items-center flex text-3xl yellow-gradient ${isChecked ? "opacity-50" : ""}`}
                        onClick={handleButtonClick}
                    >
                        <FaCheckCircle />
                    </button>
                    <button
                        className={`rounded-full text-spotGray shadow-lg justify-center items-center flex text-3xl yellow-gradient`}
                        style={{ flex: 1, height: "50px", opacity: deleteOpacity }}
                        onClick={handleDelete}
                    >
                        <FaTrashCan />
                    </button>
                    <button
                        className={`${isDarkened && "opacity-50"} rounded-full text-spotGray shadow-lg justify-center items-center flex text-3xl yellow-gradient`}
                        style={{ flex: 1, height: "50px", marginRight: "0px" }}
                        onClick={handlePrint}
                        disabled={isDarkened}
                    >
                        <FaPrint />
                        {/* {isDarkened ? "Printing..." : "Write Ticket"} */}
                    </button>
                </div>
                {showOverlay && (
                    <div className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-50">
                        <div className="flex flex-col gap-4 justify-center items-center bg-white p-6 rounded-2xl shadow-lg">
                            <h3>Select Reason for Deleting</h3>
                            <select value={deleteReason} onChange={(e) => setDeleteReason(e.target.value)}>
                                <option value="">Select Reason</option>
                                <option value="Duplicate">Duplicate</option>
                                <option value="Wrong Plate">Wrong Plate</option>
                                <option value="No Car">No Car</option>
                                <option value="Other">Other</option>
                            </select>
                            {deleteWarning === "" ? null : <p className="text-red-500 -mb-4">{deleteWarning}</p>}
                            <div className="flex flex-row gap-4 justify-center bg-transparent cursor-pointer mt-6">
                                <button onClick={handleDeleteCancel} className="light-gray-gradient rounded-lg p-2 w-20" style={{ color: "#323232" }}>Cancel</button>
                                <button onClick={handleDeleteConfirm} className="yellow-gradient rounded-lg p-2 w-20" style={{ color: "#323232" }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Toaster richColors />
        </div>
    );
};

export default Ticket;
