import React from 'react';
import TimeParked from '../../../utility/TimeSinceParked';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassEnd, faFileCircleXmark, faRectangleXmark, faFaceMeh } from '@fortawesome/free-solid-svg-icons';

// Initial destructuring of props
const ListItem = ({
    spotID,
    licensePlate,
    violationType,
    lot,
    spot,
    timeParked,
    coordinates,
    distance,
    vehicleType,
    permitNumber
}) => {
    // Destructure from argument
    const itemData = {
        spotID,
        licensePlate,
        violationType,
        lot,
        spot,
        timeParked,
        coordinates,
        distance,
        vehicleType,
        permitNumber
    };

    // Icon selection
    const handleClick = () => {
        sessionStorage.setItem('itemData', JSON.stringify(itemData));
    };

    // Icon selection. Idk why I do this in like 4 different ways. This is by far the worst.
    const iconFinder = (violationType) => {
        switch (violationType) {
            case 'Expired Meter':
                var icon = faHourglassEnd;
                break;
            case 'No Pass':
                var icon = faFileCircleXmark;
                break;
            case 'Wrong Lot':
                var icon = faRectangleXmark;
                break;
            default:
                var icon = faFaceMeh;
        }
        return (
            <FontAwesomeIcon icon={icon} className="" />
        )
    }

    return (
        <Link to={`/violation/${licensePlate}`} onClick={handleClick}>
            <div className="flex flex-row justify-between items-center font-rubik h-16 bg-white border border-gray-100 dark:bg-gradient-to-r dark:from-[#3d3d3d] dark:to-[#5c5c5c] dark:border-gray-600 self-center my-4 rounded-xl shadow-lg text-spotGray dark:text-gray-100 text-left">
                <div className="flex flex-col pl-4 justify-center">
                    <div className="font-semibold text-md">Plate #: {licensePlate}
                        <span className="font-medium text-sm ml-2">Violation: {violationType}</span>
                    </div>
                    <div className='text-sm font-normal'>
                        <span>Spot: {lot}-{spot}</span>
                        <span className='mx-1'>-</span>
                        <span>Parked for: <TimeParked dateTime={timeParked} /></span>
                    </div>
                </div>
                <div className='mr-4 text-lg'>
                    {iconFinder(violationType)}
                </div>
            </div>
        </Link>
    );
};

export default ListItem;