import React, { useState, useRef } from "react";
import { FaXmark } from "react-icons/fa6";
import TitleCard from "./TitleCard";
import { openMapWithDirections } from "../utility/openMap";
import { CiLocationArrow1 } from "react-icons/ci";
import "./ViolationCard.css";
import { navArrow } from "../../assets/svgs/navArrow";

const ViolationCard = ({ violation, onClose }) => {
    const cardRef = useRef(null);
    const headerRef = useRef(null);
    const [startY, setStartY] = useState(0);
    const [currentY, setCurrentY] = useState(0);

    //Touch event handlers
    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
        setCurrentY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        setCurrentY(e.touches[0].clientY);
        const offsetY = currentY - startY;
        if (offsetY < 0) {
            cardRef.current.style.transform = `translateY(${offsetY}px)`;
        }
    };

    const handleTouchEnd = () => {
        const offsetY = currentY - startY;
        if (offsetY < -100) {
            cardRef.current.style.transform = `translateY(-55vh)`;
        } else {
            cardRef.current.style.transform = `translateY(0)`;
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleOpenDirections = (coords) => {
        // Open directions to a destination from the user's current location
        openMapWithDirections(coords[1], coords[0]); // Destination: Provo, UT
    };

    return (
        <div>
            {violation.licensePlate === "" ?
                <div className="fixed frc gap-2 bottom-32 left-6 rounded-lg shadow-md bg-white/20 backdrop-blur-md px-6 py-8 font-bold text-left text-xl text-spotGray dark:text-gray-100 mt-4">
                    <p>Spot: {violation.lot}-{violation.spot}</p>
                    <button className="bg-gray-200 dark:bg-gray-600 rounded-full text-lg p-1 text-spotGray dark:text-gray-300 absolute top-2 right-2" onClick={handleClose}>
                        <FaXmark />
                    </button>
                    <div className="fcc border bg-transparent h-full w-16 p-2 rounded-xl shadow-md dark:bg-gray-600" onClick={() => handleOpenDirections(JSON.parse(violation.coordinates)[0])}>
                        {navArrow(32, 32)}
                    </div>
                </div>
                : <div className="z-50">
                    <div
                        className="fixed bottom-0 left-0 right-0"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div ref={cardRef} className="rounded-t-3xl h-72 bg-white/50 dark:bg-spotGray/60 border dark:border-gray-600 backdrop-blur-md">
                            <button className="bg-gray-200 dark:bg-gray-600 rounded-full text-lg p-1 text-spotGray dark:text-gray-300 absolute top-3 right-3" onClick={handleClose}>
                                <FaXmark />
                            </button>
                            <div
                                ref={headerRef}
                                onTouchStart={handleTouchStart}
                            >
                                <TitleCard violation={violation} onTouchStart={handleTouchStart} onClick={handleClose} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ViolationCard;
