import React from "react";

export const NoConnectionText = ({ title, subtitle }) => {
    return (
        <div className="flex flex-col gap-2 items-center justify-center h-screen w-screen">
            <h1 className="text-xl text-spotGray dark:text-gray-200">
                {title ? title : "No internet connection"}
            </h1>
            <h2 className="text-sm text-gray-500 dark:text-gray-400">
                {subtitle ? subtitle : "Reconnect to view data"}
            </h2>
        </div>
    )
}