import React from "react";
import SedanIcon from "../../assets/vehicleIcons/sedan.png";
import BikeIcon from "../../assets/vehicleIcons/bike.png";
import TruckIcon from "../../assets/vehicleIcons/truck.png";
import SuvIcon from "../../assets/vehicleIcons/suv.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import useInternetStatus from "../utility/useInternetStatus";
import { openMapWithDirections } from "../utility/openMap";
import { navArrow } from "../../assets/svgs/navArrow";
import "./ViolationCard.css";

const TitleCard = ({ violation }) => {
    // Map vehicle types to their respective icon file names
    const vehicleTypeIcons = {
        sedan: SedanIcon,
        bike: BikeIcon,
        truck: TruckIcon,
        suv: SuvIcon
    };

    // Function to get the icon based on the vehicle type
    const getVehicleIcon = (vehicleType) => {
        const src = vehicleTypeIcons[vehicleType] ? vehicleTypeIcons[vehicleType] : `../../assets/vehicleIcons/sedan.png`;
        const alt = vehicleTypeIcons[vehicleType] ? vehicleType : "Default Icon";

        return (
            <img src={src} alt={alt} className="h-12 dark:invert" />
        );
    };

    const timeParked = new Date(violation.timeParked);
    const formattedTimeParked = timeParked.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    const currentTime = new Date();
    const timeDifference = currentTime - timeParked;
    const totalTimeSinceParked = formatTimeDifference(timeDifference);

    // Function to format the time difference into hours and minutes
    function formatTimeDifference(time) {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours === 0 ? "" : hours !== 1 ? `${hours}hrs` : `${hours}hr`} ${minutes}mins`;
    }

    // Function to determine if there is a violation or not
    function violationBoolean() {
        (violation.violationType === "") ? null : <p>Violation: {violation.violationType}</p>
    }

    const handlePageButtonClick = () => {
        sessionStorage.setItem("itemData", JSON.stringify(violation))
    }

    const isOnline = useInternetStatus();

    const handleOpenDirections = (coords) => {
        // Open directions to a destination from the user's current location
        openMapWithDirections(coords[1], coords[0]); // Destination: Provo, UT
    };

    return (
        <div className="fcc">
            {violation.licensePlate === "" ?
                <div className="font-bold text-left text-xl text-spotGray dark:text-gray-100 mt-4">
                    <p>Spot: {violation.lot}-{violation.spot}</p>
                </div>
                : <div>
                    <div className="flex flex-row justify-between p-4 mb-4 text-spotGray dark:text-gray-100 w-screen px-10">
                        {isOnline && getVehicleIcon(violation.vehicleType)}
                        <div className="flex flex-col text-left text-xl ml-4 font-bold">
                            <p>Spot: {violation.lot}-{violation.spot}</p>
                            <p>Plate #: {violation.licensePlate}</p>
                        </div>
                    </div>
                    <div className="frb px-8">
                        <div className="text-spotGray dark:text-gray-100 flex flex-col justify-start items-start text-left w-full text-lg">
                            {violationBoolean()}
                            <p>Parked Since: {formattedTimeParked}</p>
                            <p>Parked for: {totalTimeSinceParked}</p>
                        </div>
                        <div className="frc gap-2 h-16 w-40">
                            <div className="fcc border bg-transparent h-full w-16 p-2 rounded-xl shadow-md dark:bg-gray-600" onClick={() => handleOpenDirections(JSON.parse(violation.coordinates)[0])}>
                                {navArrow(32, 32)}
                            </div>
                            {violation.violationType === "" || violation.ticketWritten === true ? null : (
                                <Link to={`/violation/${violation.licensePlate}`} className="text-3xl bg-transparent border dark:bg-gray-600 h-full w-16 rounded-xl shadow-md fcc" onClick={handlePageButtonClick}>
                                    <FontAwesomeIcon icon={faFilePen} className="text-spotGray dark:text-gray-300" />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default TitleCard;
