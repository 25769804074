export const navArrow = (width, height) => {
    const checkDarkMode = () => {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    const color = checkDarkMode() ? "#E3E5E8" : "#323232";

    return (
        <svg width={width} height={height} viewBox="0 0 190 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M184.33 198.617C181.923 200.99 178.816 202.526 175.469 202.999C172.121 203.471 168.711 202.855 165.741 201.24L165.557 201.141L94.9447 180.204L24.3617 201.141L24.1779 201.24C21.2072 202.853 17.797 203.469 14.45 202.996C11.103 202.524 7.99633 200.989 5.58801 198.617L5.37588 198.405C3.01024 196.083 1.43637 193.075 0.877853 189.807C0.319334 186.54 0.80456 183.18 2.26461 180.204L80.506 9.69925C80.5277 9.64766 80.5537 9.59799 80.5838 9.55076C81.8966 6.85905 83.9386 4.58985 86.4774 3.0013C89.0162 1.41275 91.9499 0.568616 94.9447 0.564932C97.9395 0.561249 100.875 1.39816 103.418 2.98046C105.961 4.56277 108.008 6.82694 109.328 9.5154L109.405 9.66389L187.654 180.204C189.136 183.226 189.613 186.643 189.016 189.956C188.418 193.269 186.776 196.304 184.33 198.617Z" fill={color} />
        </svg>
    )
}