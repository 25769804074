// This page is just for the map component. It pulls data from MapBox (open source map API) and displays the map on the screen.
// Each polygon is a parking spot. The color of the polygon is determined by the violation type.
// The map is interactive and can be zoomed in and out of.
// The map has default settings that can be changed by passing in props such as zoom and coordinates.

import React, { useEffect, useState } from "react";
//import RefreshButton from "./RefreshButton";
import mapboxgl from "mapbox-gl";
import { fetchAllData } from "../../utility/fetcher";
import ViolationCard from "../../violationCard/ViolationCard";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./Map.css";

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Map Creation
const Map = ({
    startSpot,
    styling,
    buttons,
    itemClickable,
    zoom,
    highlight,
    lot
}) => {
    //const navigate = useNavigate();
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [levelStates, setLevelStates] = useState({});
    const [selectedViolation, setSelectedViolation] = useState(null);
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);
    const listData = JSON.parse(sessionStorage.getItem("violationData"));
    const [currentListData, setCurrentListData] = useState(listData);
    const currentUniData = JSON.parse(sessionStorage.getItem("uniData"));
    const uniCoordinates = JSON.parse(currentUniData["orgCoordinates"]); // Pull in the coordinates from the uniDictionary
    const defaultZoom = currentUniData["defaultMapZoom"]; // Pull in the zoom from the uniDictionary
    const orgID = currentUniData["orgID"];
    const isDarkModeActive = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    let maxLevel = 0;
    let minLevel = 0;

    for (let i in listData) {
        if (parseInt(listData[i].level) > maxLevel) {
            maxLevel = parseInt(listData[i].level)
        }
        if (parseInt(listData[i].level) < minLevel) {
            minLevel = parseInt(listData[i].level)
        }
    }

    let levels = {};
    for (let i = minLevel; i <= maxLevel; i++) {
        levels[i] = false;
        levels[0] = true;
    }

    useEffect(() => {
        setLevelStates(levels);
        let intervalId = null;

        const getNewData = async () => {
            const newData = await fetchAllData({ "spotData": `/${orgID}/spots` });
            setCurrentListData(newData.spotData);
        };

        // Every ten seconds, update the map data. This is a very expensive operation. Not in regard to the code, but in regard to the cost of the API calls.
        const startPeriodicDataGetter = () => {
            intervalId = setInterval(async () => {
                await getNewData();
            }, 2000); //! This has direct influence on our costs. Think deeply if you are wanting to change this.
        };

        const stopPeriodicDataGetter = () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startPeriodicDataGetter();
            } else {
                stopPeriodicDataGetter();
            }
        };

        // Set up event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Initial start when the component is first mounted
        if (document.visibilityState === 'visible') {
            startPeriodicDataGetter();
        }

        // Cleanup function
        return () => {
            stopPeriodicDataGetter();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
    }, []);

    // Check if there are any violations and redirect if not
    if (listData.length === 0) {
        window.location.href = "/";
    }

    navigator.permissions.query({ name: "geolocation" })
        .then((result) => {
            if (result.state === "denied") {
                // Reprompt for geolocation permissions
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Success callback
                        console.log("Geolocation permissions granted");
                        // Do something with the position data
                    },
                    (error) => {
                        // Error callback
                        console.error("Geolocation permissions denied");
                    }
                );
            }
        })
        .catch((error) => {
            console.error(error);
        });

    if (navigator.geolocation) {
        navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                    //If granted then you can directly call your function here
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            // Code to execute when geolocation is successfully obtained
                            const { latitude, longitude } = position.coords;
                            console.log("Latitude:", latitude);
                            console.log("Longitude:", longitude);
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                } else if (result.state === "denied") {
                    //If denied then you have to show instructions to enable location
                }
                result.onchange = function () {
                    console.log(result.state);
                };
            });
    } else {
        alert("Sorry Not available!");
    }

    // Default settings
    const startSpotCoordinates = startSpot ? startSpot : uniCoordinates;
    const mapZoom = zoom ? zoom : defaultZoom;
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const itemClickableBool = itemClickable === undefined ? true : itemClickable;
    const highlightedItem = highlight ? highlight : null;
    const lotNumber = lot ? lot : null;
    const baseZoom = 18; // Line Size and base zoom have an inverse relationship
    const baseWidth = 1;
    const mapCss = styling ? styling : { // Default styling
        flex: "1",
        top: "0%",
        right: "0px",
        position: "relative",
        touchAction: "none",
        width: "100vw",
        height: "105vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
    };

    // Color Scheme
    if (darkMode === true) {
        var colorScheme = "mapbox://styles/mapbox/dark-v10?"
    } else {
        var colorScheme = "mapbox://styles/mapbox/light-v10"
    }

    const handleCloseViolationCard = () => {
        //const violationColor = selectedViolation.violationType === "N/A" ? "#aad7a4" : "#D1807D";
        //map.setPaintProperty(`polygon-layer-${selectedViolation.licensePlate}`, "fill-color", violationColor); // Change fill-color back to original
        setSelectedViolation(null);
    };

    // Map Object
    useEffect(() => {
        // Create map
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpotCoordinates,
            zoom: mapZoom
        });

        // Add geolocate control to the map.
        newMap.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            })
        );

        // Create map
        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            setIsStyleLoaded(true);
            await addAllPolygons(newMap);
            addLines(newMap);
        });

        return () => {
            newMap.remove();
        };
    }, []);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addAllPolygons(map);
        }
        // eslint-disable-next-line
    }, [currentListData, currentLevel]);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addLines(map);
        }
        // eslint-disable-next-line
    }, [currentLevel]);

    const addAllPolygons = async (map) => {
        // Convert your list data into GeoJSON features
        const coordinatesArray = currentListData.map((item) => [
            JSON.parse(item.coordinates),
            item.licensePlate,
            item.lot,
            item.permitNumber,
            item.spot,
            item.ticketWritten,
            item.timeParked,
            item.vehicleType,
            item.violationType,
            item.spotID,
            item.cameras,
            item.permitType,
            item.level,
        ]);

        const features = coordinatesArray.map(([coordinates, licensePlate, lot, permitNumber, spot, ticketWritten, timeParked, vehicleType, violationType, spotID, cameras, permitType, level]) => {
            // const lots = currentOrgData["lots"];
            // const currentLot = lots[lot - 1];
            const onOffStatus = true; // Placeholder, adjust if needed

            let fillColor;
            if (onOffStatus === false) {
                fillColor = "#aad7a4";
            } else if (licensePlate === "") {
                fillColor = "transparent";
            } else if (ticketWritten === true) {
                fillColor = "#9C9C9C";
            } else if (violationType === "") {
                fillColor = "#aad7a4";
            } else {
                fillColor = "#E57373";
            }

            //TODO Make spots on a different level a lighter color but not clickable
            // if (currentLevel !== level || currentLevel === undefined) {
            //     if (fillColor !== "transparent") {
            //         fillColor = fillColor + "50";
            //     }
            // }

            // Return a GeoJSON feature if it's on the current level
            if (currentLevel.toString() === level) {
                return {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinates]
                    },
                    properties: {
                        id: `polygon-layer-${spotID}`,
                        violationType: violationType,
                        licensePlate: licensePlate,
                        spot: spot,
                        lot: lot,
                        timeParked: timeParked,
                        vehicleType: vehicleType,
                        permitNumber: permitNumber,
                        coordinates: coordinates,
                        ticketWritten: ticketWritten,
                        fillColor: fillColor,
                        spotID: spotID,
                        cameras: cameras,
                        permitType: permitType,
                        level: level
                    }
                };
            } else {
                return null
            }
        }).filter(feature => feature !== null);

        // Create a GeoJSON feature collection
        const featureCollection = {
            type: "FeatureCollection",
            features: features
        };

        // Add or update the source and layer on the map
        if (map.getSource('polygons')) {
            map.getSource('polygons').setData(featureCollection);
        } else {
            map.addSource('polygons', {
                type: 'geojson',
                data: featureCollection
            });

            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygons',
                paint: {
                    'fill-color': ['get', 'fillColor'],
                    'fill-opacity': 1,
                    'fill-outline-color': 'transparent',
                }
            });
        }

        // Calls the violation card when a polygon is clicked
        map.on('click', 'polygon-layer', (e) => {
            const clickedFeature = e.features[0];
            const properties = clickedFeature.properties;

            const { licensePlate, spot, timeParked, vehicleType, ticketWritten, permitNumber, violationType, coordinates, lot, spotID, cameras, permitType, level } = properties;
            setSelectedViolation({ licensePlate, spot, timeParked, vehicleType, ticketWritten, permitNumber, violationType, coordinates, lot, spotID, cameras, permitType, level });
        });

        // Handle clicks outside polygons to deselect
        map.on('click', (e) => {
            const features = map.queryRenderedFeatures(e.point, { layers: ['polygon-layer'] });

            if (!features.length) {
                // Clear all selections
                handleCloseViolationCard();
                setSelectedViolation(null); // Clear the selected violation
            }
        });
    };

    function jsonBuilder(arr) {
        var finalJson = []

        arr.forEach((lot) => {
            for (let i in lot) {
                const level = lot[i].level
                const lines = lot[i].lines
                if (currentLevel.toString() === level) {
                    for (let i in lines) {
                        finalJson.push({
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "coordinates": lines[i],
                                "type": "LineString"
                            }
                        })
                    }
                }
            }
        })

        return finalJson
    }

    // Snag line data from the session storage
    const cordInput = JSON.parse(sessionStorage.getItem("lineData"));

    //Add lines to the map. Gets its data from the jsonBuilder function 
    const addLines = async (map) => {
        // Remove existing layer if it exists
        if (map.getLayer('route')) {
            map.removeLayer('route');
        }

        // Remove existing source if it exists
        if (map.getSource('route')) {
            map.removeSource('route');
        }

        map.addSource('route', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": jsonBuilder(cordInput)
            }
        });

        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'square',
                'line-sort-key': 10
            },
            paint: {
                'line-color': '#ffe374',
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [0, baseWidth * Math.pow(2, (0 - baseZoom))],
                        [24, baseWidth * Math.pow(2, (24 - baseZoom))]
                    ]
                }
            }
        });
    };

    const [open, setOpen] = useState(false);
    const Diamond = ({ fill, level }) => {
        return (
            <div className="absolute group cursor-pointer">
                {/* Tooltip */}
                {open && (
                    <p className={`absolute ${level === 0 ? "right-[-183%]" : "right-[-120%]"} top-1/2 transform -translate-y-1/2 bg-spotGray dark:bg-gray-600 px-2 h-6 fcc font-bold text-[10px] rounded-md rotate-180`}>
                        {level === 0 ? "Ground Level" : `Level ${level + 1}`}
                    </p>
                )}
                {/* Diamond */}
                <svg
                    width="1861"
                    height="976"
                    viewBox="0 0 1861 976"
                    className={`w-12 h-auto`}
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setOpen(!open)}
                    style={{
                        fill: isDarkModeActive ? fill ? "#C0C2C6" : "#343332" : fill ? "#4F545C" : "white",
                        transition: "fill 0.3s ease",
                    }}
                >
                    <path
                        d="M965.063 63.3257L1753.23 409.251C1821.82 439.352 1821.82 536.648 1753.23 566.749L965.063 912.674C943.035 922.342 917.965 922.342 895.937 912.674L107.766 566.749C39.1833 536.648 39.1832 439.352 107.766 409.251L895.937 63.3257C917.965 53.6578 943.035 53.6578 965.063 63.3257Z"
                        stroke={`${isDarkModeActive ? "#C0C2C6" : "#4F545C"}`}
                        strokeWidth="90"
                    />
                </svg>
            </div>
        );
    };

    const changeLevel = (index) => {
        const newLevelStates = { ...levelStates };
        Object.keys(newLevelStates).forEach((key) => {
            newLevelStates[key] = false;
        });
        newLevelStates[index] = !newLevelStates[index];
        setCurrentLevel(index);
        setLevelStates(newLevelStates);
    }

    // Display map with or without buttons
    if (buttons === false) {
        return (
            <div id="map-container" style={mapCss}>
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div id="map-container" style={mapCss}>
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                {Object.entries(levelStates).length > 1 && (
                    <div className={`flex flex-col absolute ${selectedViolation ? "bottom-[345px]" : "bottom-[160px]"} right-6 z-10 gap-2 text-2xl text-white`} onClick={() => setOpen(!open)}>
                        <div className={`ml-16 fcc rotate-180 ${open ? "gap-8" : "gap-[10px]"} transition-all duration-500 ease-in-out`}>
                            {Object.entries(levelStates).map(([key, fill], index) => (
                                <div onClick={() => changeLevel(index)} key={index} className={`${!open && "pointer-events-none"}`}>
                                    <Diamond fill={fill} level={index} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {selectedViolation && (
                    <div className="z-50">
                        <ViolationCard violation={selectedViolation} onClose={handleCloseViolationCard} />
                    </div>
                )}
            </div>
        );
    }
};

export default Map;
