// Author: Ryan Hagerty

import { createRoot } from "react-dom/client";
import App from "./App";
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(function (registration) {
            console.log('Service Worker registered with scope: ', registration.scope);
        }, function (err) {
            console.log('Service Worker registration failed: ', err);
        });
    });
}

root.render(
    <App />
);

// Add <StrictMode> to the root element if things are acting wild