import React, { useState } from "react";
import { FaPlateWheat, FaPrint } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { GoPlus } from "react-icons/go";

export const NewViolation = (route) => {
    const [clicked, setClicked] = useState(false);
    const [plate, setPlate] = useState("");
    const [violation, setViolation] = useState("");
    const currentLot = JSON.parse(sessionStorage.getItem("lotData")).lot;
    const [lot, setLot] = useState(currentLot);
    const currentTime = new Date()
    const [time, setTime] = useState(formatDateTimeForInput(currentTime));
    const [fee, setFee] = useState("");
    const user = sessionStorage.getItem("user")
    const [employee, setEmployee] = useState(user);
    const [isDarkened, setIsDarkened] = useState(false);
    const allViolations = JSON.parse(sessionStorage.getItem("uniData")).violationTypes
    const violationArray = ["Select Violation", ...Object.keys(allViolations)];
    const allLotData = JSON.parse(sessionStorage.getItem("sortedLotArray"))

    var allLots = []
    for (let i in allLotData) {
        const lot = allLotData[i].lot + ": " + allLotData[i].lotName
        allLots.push(lot)
    }

    const currentViolationUrl = "https://aza3hblk9j.execute-api.us-east-1.amazonaws.com/default/API_Tester";
    const historyUrl = "https://thprlfo0gl.execute-api.us-east-1.amazonaws.com/default/UniversityHistoryAPI"
    const apiKey = process.env.REACT_APP_API_KEY;

    const printReceipt = () => {
        const options = { hour: "numeric", minute: "numeric", hour12: true, timeZone: "UTC" };
        const time = new Date(TimeSinceParked).toLocaleTimeString([], options);
        const date = new Date(TimeSinceParked).toLocaleDateString();
        const timeParked = `${date} ${time}`;
        const type = VehicleType.charAt(0).toUpperCase() + VehicleType.slice(1)
        const receiptContent = `
<pre>
--------------------------------
       PARKING CITATION
   Citation Number: 00000001
--------------------------------
License Plate: ${plate}

Violation: ${violation}

Lot: ${lot}

Vehicle Type: ${type}

Time Parked: ${timeParked}

Fee: ${fee}

Employee: ${employee}
--------------------------------
CITATION MUST BE CLEARED WITHIN
14 CALENDAR DAYS OF ISSUE.
VIOLATIONS OF PARKING
REGULATIONS MAY SUBJECT YOUR
VEHICLE TO BEING BOOTED OR TOWED
AT OWNER'S EXPENSE.
--------------------------------
<pre>
        `;

        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(receiptContent);
            printWindow.document.close();
            printWindow.print();
        } else {
            console.error("Failed to open print window");
        }
    };

    const handlePrint = () => {
        validationCheck();
        setIsDarkened(true);
        setTimeout(() => {
            printReceipt();
            setIsDarkened(false);
            resetButtonData();
        }, 1000);
    };

    const jsonData = {
        "LicensePlate": plate,
        "Coordinates": `[[${route.latitude || 0}, ${route.longitude || 0}]]`,
        "Distance": "",
        "PermitNumber": "",
        "Lot": lot,
        "Spot": "N/A",
        "TicketWritten": true,
        "TimeSinceParked": "",
        "VehicleType": "",
        "ViolationType": violation
    }

    const handleButtonClick = async () => {
        validationCheck();
        // Push to live data
        fetch(currentViolationUrl, { // TODO Update this to the new API setup
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-api-key": apiKey
            },
            body: JSON.stringify(jsonData)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error(error);
            });

        // Save data to history
        const historyData = JSON.parse(sessionStorage.getItem("violationHistory"));
        console.log(historyData)
        var historyArray = historyData.length === 0 ? [] : historyData.find(item => item.LicensePlate === plate)?.History || [];
        console.log(historyArray)

        let newFeeInput = "";
        if (fee.includes("$")) {
            newFeeInput = fee.substring(1);
        }

        const newPush = {
            "employee": employee,
            "lot": lot,
            "permitType": "",
            "spot": "N/A",
            "stolen": "false",
            "ticketWritten": "true",
            "ticketWrittenTime": currentTime,
            "timeIn": "",
            "timeOut": "",
            "violationType": violation,
            "fee": fee,
            "deleteReason": ""
        }

        historyArray.push(newPush);

        const historyBuild = {
            "LicensePlate": plate,
            "History": historyArray
        }

        // Push to history
        try {
            console.log("HISTORY Data to save:", historyBuild);
            const response = await fetch(historyUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'x-api-key': apiKey,
                },
                body: JSON.stringify(historyBuild),
            });
            if (response.ok) {
                console.log("Data saved successfully!");
            } else {
                console.log("Failed to save data.");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }

        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.LicensePlate === plate);
        if (foundItem) {
            violationData = violationData.filter(item => item.LicensePlate !== plate);
            foundItem.TicketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }
    };

    const validationCheck = () => {
        if (plate === "" || violation === "Select Violation" || lot === "" || time === "" || fee === "" || employee === "") {
            alert("Please fill out all fields before submitting.");
        }
    };

    const handleCheck = () => {
        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.LicensePlate === plate);
        if (foundItem) {
            violationData = violationData.filter(item => item.LicensePlate !== plate);
            foundItem.TicketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }

        setTimeout(() => {
            setClicked(false);
            resetButtonData();
        }, 1000);
    };

    const resetButtonData = () => {
        setPlate("");
        setViolation("");
        setLot(currentLot);
        setTime(formatDateTimeForInput(currentTime));
        setFee("");
        setEmployee(user);
    };

    function formatDateTimeForInput(dateTimeString) {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const parseDateInput = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? dateString : date.toISOString();
    };

    const feeBlur = () => {
        if (fee !== "") {
            setFee(`$${parseFloat(fee).toFixed(2)}`);
        }
    }

    return (
        <div className={` ${clicked ? "w-[calc(100%_-_45px)] h-[570px] rounded-xl shadow-md backdrop-blur-lg flex flex-col items-center" : "w-16 h-16 rounded-xl text-4xl fcc"} fixed bottom-32 right-6 transition-all duration-300 ease-in-out bg-gray-300/60 dark:bg-spotGray/60 dark:border dark:border-gray-600 backdrop-blur-sm text-black dark:text-gray-100`}>
            <GoPlus className={`${clicked ? "rotate-[225deg] bottom-[14px] right-[14px] text-4xl" : ""} absolute bottom-[14px] right-[14px] text-4xl transition-all duration-500 ease-in-out`} onClick={() => setClicked(!clicked)} />
            <div className={`ticket-details transition-all w-[90%] ${clicked ? "clicked mt-4" : "dafault"}`}>
                <div className="flex p-1 flex-row items-start flex-wrap gap-2">
                    <div className="flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-lg grow shadow-md dark:border-gray-600">
                        <p className="text-lg font-bold">Plate #:</p>
                        <input
                            type="text"
                            value={plate}
                            placeholder="ex. ABC123"
                            onChange={(e) => setPlate(e.target.value)}
                            onClick={() => setPlate("")}
                            onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                            className="text-sm p-1 pl-2 mt-2 w-full bg-white text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        />
                    </div>
                    <div className={`flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-md grow shadow-md dark:border-gray-600 transition-all duration-200 delay-150 ${clicked ? "scale-100" : "scale-0"}`}>
                        <p className="text-lg font-bold">Parking Violation:</p>
                        <select
                            value={violation}
                            onChange={(e) => setViolation(e.target.value)}
                            className="text-sm p-1 pl-2 mt-2 w-full rounded-md bg-white text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        >
                            {violationArray.map((violation, index) => (
                                <option key={index} className="text-spotGray text-xl" value={violation}>{violation}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex p-1 flex-row items-start flex-wrap gap-3">
                    <div className={`flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-md grow shadow-md dark:border-gray-600 transition-all duration-200 delay-150 ${clicked ? "scale-100" : "scale-0"}`}>
                        <p className="text-lg font-bold">Lot:</p>
                        <select
                            value={lot}
                            onChange={(e) => setLot(e.target.value)}
                            onBlur={() => { lot === "" && setLot(currentLot) }}
                            className="text-sm p-1 pl-2 mt-2 w-full rounded-md bg-white text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        >
                            {allLots.map((lot) => (
                                <option className="text-spotGray text-xl" value={lot}>{lot}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-md grow shadow-md dark:border-gray-600 transition-all duration-200 delay-150 ${clicked ? "scale-100" : "scale-0"}`}>
                        <p className="text-lg font-bold">Time Cited:</p>
                        <input
                            type="datetime-local"
                            value={time ? formatDateTimeForInput(time) : ""}
                            onChange={(e) => setTime(parseDateInput(e.target.value))}
                            onClick={() => setTime("")}
                            onBlur={() => { time === "" && setTime(currentTime) }}
                            onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                            className="text-sm p-1 pl-2 mt-2 w-full text-spotGray dark:text-gray-100 dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        />
                    </div>
                </div>
                <div className="flex p-1 flex-row items-start flex-wrap gap-3">
                    <div className={`flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-md grow shadow-md dark:border-gray-600 transition-all duration-200 delay-150 ${clicked ? "scale-100" : "scale-0"}`}>
                        <p className="text-lg font-bold">Fee:</p>
                        <input
                            type="text"
                            value={fee}
                            placeholder="ex. $25.00"
                            onChange={(e) => setFee(e.target.value)}
                            onClick={() => setFee("")}
                            onBlur={feeBlur}
                            onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                            className="text-sm p-1 pl-2 mt-2 w-full dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        />
                    </div>
                    <div className="flex flex-col items-start rounded-xl p-2 border bg-white/60 dark:bg-spotGray backdrop-blur-md grow shadow-md dark:border-gray-600 transition-all duration-200 delay-150">
                        <p className="text-lg font-bold">Employee Name:</p>
                        <input
                            type="text"
                            value={employee}
                            placeholder="ex. John Smith"
                            onChange={(e) => setEmployee(e.target.value)}
                            onClick={() => setEmployee("")}
                            onBlur={() => { employee === "" && setEmployee(user) }}
                            onKeyDown={(e) => { e.key === "Enter" && e.target.blur() }}
                            className="text-sm p-1 pl-2 mt-2 w-full dark:bg-spotGray dark:border dark:border-gray-600"
                            style={{ outline: 'none' }}
                        />
                    </div>
                </div>
            </div>
            <div className={`fixed z-50 justify-center frc gap-2 bottom-4 left-4 w-[80%] justify-self-center ${clicked ? "scale-100 opacity-100 delay-200 duration-300 mt-4" : "duration-0 scale-0 opacity-0 delay-0"}`}>
                <button
                    className={`rounded-full text-spotGray shadow-md justify-center items-center flex text-3xl bg-white/60 backdrop-blur-md dark:border dark:border-gray-600 flex-1 h-10`}
                    onClick={() => {
                        handleButtonClick();
                        handleCheck();
                    }}
                >
                    <FaCheckCircle />
                </button>
                <button
                    className={`rounded-full text-spotGray shadow-md justify-center items-center flex text-3xl bg-white/60 backdrop-blur-md dark:border dark:border-gray-600 flex-1 h-10`}
                    onClick={handlePrint}
                    disabled={isDarkened}
                >
                    <FaPrint />
                </button>
            </div>
        </div>
    );
}