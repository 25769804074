export function getFirstName() {
    const allUserData = JSON.parse(localStorage.getItem('userData'));
    for (let i in allUserData) {
        if (allUserData[i].Name === "given_name") {
            return allUserData[i].Value
        }
    }
}

export function getLastName() {
    const allUserData = JSON.parse(localStorage.getItem('userData'));
    for (let i in allUserData) {
        if (allUserData[i].Name === "family_name") {
            return allUserData[i].Value
        }
    }
}

export function getFullName() {
    return `${getFirstName()} ${getLastName()}`
}