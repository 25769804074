import React from "react";

// Idk why this just isn't in the main component
const TimeParked = ({ dateTime }) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true, timeZone: "UTC" };
    const timeString = new Date(dateTime).toLocaleTimeString([], options);
    return <span>{timeString}</span>;
};

export default TimeParked;
