import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./components/login/Login";
import Cookies from "js-cookie";
import NavBar from "./components/header/NavBar";
import "./styles.css";
import { ResetPassword } from "./components/login/ResetPassword";
import { SignUp } from "./components/login/SignUp";
import { ContactUs } from "./components/login/ContactUs";
import { LotView } from "./components/pages/home/lotView/LotView";
import ListView from "./components/pages/home/listView/ListView";
import MapView from "./components/pages/mapView/MapView";
import ViolationPage from "./components/pages/ViolationPage";
import { Profile } from "./components/pages/Profile";
import { Announcements } from "./components/pages/announcements/Announcements";

export default function App() {
    const [splash, setSplash] = useState(false);
    const jsCookie = Cookies.get("accessToken");

    // Prevents pinch zoom on mobile. No need to touch
    useEffect(() => {
        const disablePinchZoom = (event) => {
            if (event.touches.length > 1) event.preventDefault();
        };
        document.addEventListener("touchmove", disablePinchZoom, { passive: false });
        return () => {
            document.removeEventListener("touchmove", disablePinchZoom);
        };
    }, []);

    // If the user is not authenticated, redirect to login page
    if (jsCookie === undefined) {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("accessToken");

        const allowedPaths = ["/login", "/contact-us", "/reset-password", "/reset-password"];
        if (!allowedPaths.includes(window.location.pathname)) window.location.href = "/login";
    }

    const onSplash = (newState) => {
        setSplash(newState)
    };

    return (
        <div className="">
            <Router>
                {jsCookie === undefined ? null : !splash && <NavBar />}
                <Routes>
                    <Route path="/" element={<LotView onSplash={onSplash} />} />
                    <Route path="/listView/:lot" element={<ListView />} />
                    <Route path="/mapView" element={<MapView />} />
                    <Route path="/announcements" element={<Announcements />} />
                    <Route path="/violation/:licensePlate" element={<ViolationPage />} />
                    <Route path="/profile" element={<Profile />} />
                    {/* Sign up stuff */}
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
            </Router>
        </div >
    );
}
