import React, { useEffect, useState } from 'react';
import Ticket from '../ticket/Ticket';
import Map from './mapView/Map';
import { FaArrowLeft } from 'react-icons/fa';
import { fetchAllData } from '../../fetcher';
import { getFullName } from '../utility/getNames';

const ViolationPage = () => {
    // Initialize states and user info
    const [violationData, setViolationData] = useState(JSON.parse(sessionStorage.getItem('itemData')));
    const [currentHistory, setCurrentHistory] = useState([]);
    const currentUniData = JSON.parse(sessionStorage.getItem("uniData"));
    const orgID = currentUniData.orgID;
    const schoolName = currentUniData["UniversityName"];
    const fullName = getFullName();

    useEffect(() => {
        window.scrollTo(0, 0);

        const itemData = sessionStorage.getItem('itemData');
        if (itemData) {
            setViolationData(JSON.parse(itemData));
        }
    }, []);

    const getHistory = async () => {
        const path = { "violationHistory": `/${orgID}/history?licensePlate=${violationData.licensePlate}` }
        const response = await fetchAllData(path);

        if (response.violationHistory.error) {
            setCurrentHistory([]);
        } else {
            const historyData = response.violationHistory.history.sort((a, b) => new Date(b.timeIn) - new Date(a.timeIn));
            const mostRecentViolationVisits = historyData.filter(item => item.violationType !== "").slice(0, 3);
            const visitsToFirstViolation = historyData.indexOf(mostRecentViolationVisits[0]);
            const visitsToSecondViolation = historyData.indexOf(mostRecentViolationVisits[1]) === -1 ? historyData.length - visitsToFirstViolation - 1 : historyData.indexOf(mostRecentViolationVisits[1]) - visitsToFirstViolation;
            const visitsToThirdViolation = historyData.indexOf(mostRecentViolationVisits[2]) - visitsToSecondViolation - visitsToFirstViolation;

            console.log(visitsToFirstViolation, visitsToSecondViolation, visitsToThirdViolation);

            setCurrentHistory({
                "firstViolation": mostRecentViolationVisits[0],
                "secondViolation": mostRecentViolationVisits[1],
                "thirdViolation": mostRecentViolationVisits[2],
                "visitsToFirstViolation": visitsToFirstViolation,
                "visitsToSecondViolation": visitsToSecondViolation,
                "visitsToThirdViolation": visitsToThirdViolation,
                "fullHistory": historyData
            });
        }
    }

    useEffect(() => {
        getHistory();
    }, [violationData]);

    const plateHistory = currentHistory

    // Turns the coordinates into a usable format aka string to array
    var string1 = violationData.coordinates.slice(2) // Removes the first two characters
    const cord1 = string1.split(',')[0]; // Splits the string at the comma and takes the first half
    const cord2 = string1.split(',')[1].slice(0, -1); // Same then removes the last character

    // Map data
    const startSpot = new mapboxgl.LngLat(cord1, cord2);
    const zoom = 20;
    const itemClickable = false;
    const buttons = false
    const styling = {
        flex: "1",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        position: "relative",
        touchAction: "none",
        width: "95%",
        height: "400px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "2px 2px 8px 3px rgba(0, 0, 0, 0.2)",
    }

    const generatePlateHistoryTable = () => {
        return (
            <div className='z-20 w-full fcc bg-white/50 backdrop-blur-md shadow-md border rounded-2xl p-2'>
                <h1 className='text-2xl font-bold text-center my-2'>Plate History</h1>
                <p className="w-full text-center font-bold text-gray-500">Current Day</p>
                <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToFirstViolation > 10 ? "h-6" : "h-3"}`}></div>
                <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToFirstViolation} clean visits</p>
                {currentHistory.firstViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToFirstViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.firstViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.firstViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.firstViolation.fee)})</p>
                        </div>
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToSecondViolation > 10 ? "h-6" : "h-3"}`}></div>
                    </div>
                }
                <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToSecondViolation} clean visits</p>
                {currentHistory.secondViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToSecondViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.secondViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.secondViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.secondViolation.fee)})</p>
                        </div>
                    </div>
                }
                {currentHistory.thirdViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToThirdViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToThirdViolation} clean visits</p>
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToThirdViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.thirdViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.thirdViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.thirdViolation.fee)})</p>
                        </div>
                    </div>
                }
            </div >
        );
    };

    const formatDate = (input) => {
        const date = new Date(input);
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        let day = String(date.getDate()).padStart(2, '0');
        let year = String(date.getFullYear()).slice(-2);
        return `${month}-${day}-${year}`;
    };

    const formatFee = (fee) => {
        return `$${parseFloat(fee).toFixed(2)}`;
    };

    return (
        <div className='fcc w-screen gap-4 font-rubik'>
            <button key={"backButton"} onClick={() => { window.history.back() }} className="text-spotGray dark:text-gray-100 frc absolute top-4 left-4 print:hidden">
                <FaArrowLeft />
                <span className="text-lg font-bold mt-0 ml-1"> Back</span>
            </button>
            <Ticket violationData={violationData} fullName={fullName} />
            {/* PLATE HISTORY */}
            <div className="fcc w-full">
                {plateHistory.length === 0 ? null : generatePlateHistoryTable()}
            </div>
            <div className="w-full z-0 print:hidden">
                <Map
                    startSpot={startSpot}
                    zoom={zoom}
                    styling={styling}
                    buttons={buttons}
                    itemClickable={itemClickable}
                    school={schoolName}
                    highlight={violationData.LicensePlate}
                    lot={violationData.Lot}
                />
            </div>
        </div >
    );
};

export default ViolationPage;