// * HOME PAGE
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa6";
import Header from "./Header";
import { logout } from "../../../utility/logout";
import PullToRefresh from 'react-pull-to-refresh';
import Skeleton from 'react-loading-skeleton'
import { fetchAllData } from "../../../../fetcher";
import useInternetStatus from "../../../utility/useInternetStatus";
import 'react-loading-skeleton/dist/skeleton.css'
import { NoConnectionText } from "../../../utility/NoConnectionText";

export const LotView = ({ onSplash }) => {
    const navigate = useNavigate();
    const currentData = JSON.parse(sessionStorage.getItem("violationData"));
    const [loading, setLoading] = useState(true);
    const [filterOption, setFilterOption] = useState("Lot");
    const [uniData, setUniData] = useState();
    const [listData, setListData] = useState(currentData);
    const [sortedListData, setSortedListData] = useState([]);
    const [splash, setSplash] = useState(false);
    const [testSorted, setTestSorted] = useState({});
    const currentOrg = localStorage.getItem("organization");
    if (currentOrg === null) {
        logout();
        navigate("/login");
    }

    const currentOrgName = currentOrg.replace(/\s/g, '+');
    const isOnline = useInternetStatus();

    const storeData = async () => {
        if (!isOnline) {
            console.log("No internet connection");
            setLoading(false);
            return (
                <div className="flex flex-row items-center justify-center h-screen w-screen">
                    No internet connection
                </div>
            );
        }

        try {
            const fetchAndStoreData = async (orgID, currentOrgName) => {
                const urls = {
                    violationData: `/${orgID}/spots`,
                    violationHistory: `/${orgID}/history`,
                    lineData: `/${orgID}/lines`,
                    uniData: `/public/getOrgByName?orgName=${currentOrgName}`,
                };

                const finalData = await fetchAllData(urls);

                sessionStorage.setItem("uniData", JSON.stringify(finalData.uniData));
                sessionStorage.setItem("violationData", JSON.stringify(finalData.violationData));
                sessionStorage.setItem("violationHistory", JSON.stringify(finalData.violationHistory));
                sessionStorage.setItem("lineData", JSON.stringify(finalData.lineData));

                setListData(finalData.violationData);
                setLoading(false);
                onSplash(false);
                setSplash(false);
            };

            const handleFetchData = async () => {
                const storedOrgID = localStorage.getItem("orgID");
                const storedUniData = sessionStorage.getItem("uniData");

                if (!storedOrgID || !storedUniData) {
                    setSplash(true);
                    onSplash(true);
                    const uniDataPath = { uniData: `/public/getOrgByName?orgName=${currentOrgName}` };
                    const data = await fetchAllData(uniDataPath);
                    const currentOrgData = data.uniData;
                    const orgID = currentOrgData.orgID;

                    localStorage.setItem("orgID", orgID);
                    setUniData(currentOrgData);
                    sessionStorage.setItem("uniData", JSON.stringify(currentOrgData));

                    await fetchAndStoreData(orgID, currentOrgName);
                } else {
                    await fetchAndStoreData(storedOrgID, currentOrgName);
                }
            };

            handleFetchData();
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    const loader = () => {
        const loaderItem = () => {
            const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            const backgroundColor = isDarkMode ? "#606060" : "#ebebeb";
            const highlightColor = isDarkMode ? "#888888" : "#F5F5F5";

            return (
                <div className="flex flex-row justify-between items-center h-16 bg-white dark:bg-gradient-to-r dark:from-[#3d3d3d] dark:to-[#5c5c5c] self-center my-4 rounded-xl shadow-lg text-spotGray text-left">
                    <div className="flex flex-col justify-start items-start mr-auto ml-4">
                        <Skeleton height={12} width={0.14 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                        <Skeleton height={25} width={0.25 * window.innerWidth} borderRadius={8} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                    <div className="flex flex-row mr-4">
                        <Skeleton height={15} width={0.35 * window.innerWidth} borderRadius={20} baseColor={backgroundColor} highlightColor={highlightColor} />
                    </div>
                </div>
            );
        }

        return (
            <div>
                {Array.from({ length: 10 }).map((_, index) => (
                    <div key={index}>
                        {loaderItem()}
                    </div>
                ))}
            </div>
        )
    }

    const LotItem = ({ lotObj }) => {
        const percentage = Math.round((lotObj.occupancy / lotObj.capacity) * 100);

        const items = lotObj.items;
        const violationCount = items.filter((item) => item.violationType !== "" && item.ticketWritten === false).length;

        // Icon selection
        const handleClick = () => {
            sessionStorage.setItem('lotData', JSON.stringify(lotObj));
        };

        function getLotName() {
            const currentLot = lotObj["lot"];
            const uniData = JSON.parse(sessionStorage.getItem("uniData"));
            const lots = uniData["lots"];
            const filteredLot = lots.filter((lot) => lot.lotNum === currentLot);
            return filteredLot[0].lotName;
        }

        const barChart = (percentage) => {
            var color = ""
            if (percentage <= 25) {
                color = "#FFE374" //"#C6F1CA"
            } else if (percentage <= 80) {
                color = "#FFE374"
            } else {
                color = "#FFE374" //"#FDC1C0"
            }

            return (
                <div className="flex flex-col mr-4">
                    <div key={"percentage"} className="text-sm rubik-normal dark:text-gray-100">{percentage}% Filled</div>
                    <div key={"bar"} className="flex flex-row w-[25vw] h-4 bg-yellow-100 rounded-full shadow-sm">
                        <div key={"fill"} className="h-full rounded-full shadow-md" style={{ width: `${percentage}%`, backgroundColor: color }}></div>
                    </div>
                </div>
            )
        }

        return (
            <Link to={`/listView/${lotObj["lot"]}`} onClick={handleClick}>
                <div className="flex flex-row justify-between items-center rubik-normal h-16 bg-white border border-gray-100 self-center dark:bg-gradient-to-r dark:from-[#3d3d3d] dark:to-[#5c5c5c] dark:border-gray-600 my-4 rounded-xl shadow-lg text-spotGray text-left">
                    <div className="flex flex-col pl-4 w-full max-w-xs overflow-hidden">
                        <h2 className="text-lg rubik-medium text-spotGray dark:text-gray-100 whitespace-nowrap overflow-hidden text-ellipsis">Lot {lotObj["lot"]}:&nbsp;
                            <span className="text-base rubik-normal">{getLotName()}</span>
                        </h2>
                        <div className="text-sm whitespace-nowrap dark:text-gray-100 overflow-hidden text-ellipsis">Violation Count: {violationCount}</div>
                    </div>
                    <div className="flex flex-row items-center">
                        {barChart(percentage)}
                        <FaAngleRight className="text-xl text-gray-400 mr-2" />
                    </div>
                </div>
            </Link>
        );
    }

    const applySorter = (data) => {
        return data?.sort((a, b) => {
            switch (filterOption) {
                case "Violation Count":
                    return b.items.length - a.items.length;
                case "Lot":
                    return a.lot.localeCompare(b.lot, undefined, { numeric: true });
                default:
                    return 0;
            }
        });
    };

    const handleRefresh = () => {
        setLoading(true);
        setSplash(false)
        storeData();
    }

    // Set bottom margin depending on device
    var mt = "mt-16"
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        mt = "mt-0"
    }

    // Start by fetching data
    useEffect(() => {
        if (uniData !== null && uniData !== undefined && uniData !== "undefined") {
            setLoading(false);
        } else if (!currentData) {
            storeData();
        } else {
            setLoading(false);
        }
    }, []);

    let sortedLotArray = [];
    useEffect(() => {
        if (listData) {
            // Filter out the data that is not a violation or has already been ticketed

            let lotArray = [];
            if (listData !== undefined && listData.length !== 0 && listData !== null) {
                const lotMap = {};
                listData.forEach((item) => {
                    const lot = item.lot;
                    if (!lotMap[lot]) {
                        lotMap[lot] = [];
                    }
                    if (item.violationType !== "") {
                        lotMap[lot].push(item);
                    }
                });
                lotArray = Object.entries(lotMap).map(([lot, items]) => ({ lot, items }));
            }

            const violationData = JSON.parse(sessionStorage.getItem("violationData"));
            const uniData = JSON.parse(sessionStorage.getItem("uniData"));
            const lotData = uniData ? uniData["lots"] : null;

            // Add capacity and occupancy to each lot
            if (lotArray.length !== 0) {
                for (let i in lotData) {
                    const currentLot = lotArray.find((lot) => lot.lot === lotData[i]["lotNum"])
                    const currentOccupancy = violationData.filter(item => item.lot === lotData[i]["lotNum"] && item.licensePlate !== "").length;
                    const currentCapacity = violationData.filter(item => item.lot === lotData[i]["lotNum"]).length;
                    const lotName = lotData[i]["lotName"];

                    if (currentLot === undefined) {
                        lotArray.push({ lot: lotData[i]["lotNum"], items: [], capacity: currentCapacity, occupancy: currentOccupancy })
                    } else {
                        currentLot.capacity = currentCapacity
                        currentLot.occupancy = currentOccupancy
                        currentLot.lotName = lotName
                    }
                }
            }

            sortedLotArray = applySorter(lotArray);
            setSortedListData(sortedLotArray);
            sessionStorage.setItem("sortedLotArray", JSON.stringify(sortedLotArray));
        }
    }, [listData]);

    const sortItems = ["Violation Count", "Lot"];

    if (splash) {
        return (
            <div className="flex flex-row items-center justify-center h-screen w-screen dark:bg-spotGray">
                <div className="absolute top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2 dark:bg-spotGray">
                    <div className="absolute text-[75px] ml-1 top-[-113px] font-bold text-spotYellow text-center px-5 typewriter font-rubik animate-typing">SPOT</div>
                    <div id="top" className="absolute top-[-120px] h-4 rounded-sm yellow-gradient animate-grow-line-1"></div>
                    <div className="absolute top-[-10px] h-4 rounded-sm yellow-gradient animate-grow-line-2"></div>
                    <div className="absolute top-[-105px] w-4 rounded-sm bg-spotYellow animate-grow-line-3"></div>
                </div>
            </div>
        )
    } else if (loading) {
        return (
            <div className="fcc w-screen h-auto">
                <Header defaultSelection={"Lot"} items={sortItems} handleSort={setFilterOption} onChange={(e) => setFilterOption(e.target.value)} />
                <div className={`w-[95%] ${mt}`}>
                    {loader()}
                </div>
            </div>
        );
    }

    return (
        <PullToRefresh onRefresh={handleRefresh}>
            <div className="fcc w-screen h-auto">
                <Header defaultSelection={"Lot"} items={sortItems} handleSort={setFilterOption} onChange={(e) => setFilterOption(e.target.value)} />
                {isOnline ?
                    <div className={`w-[95%] ${mt}`}>
                        {sortedListData.map((lotObj, index) => (
                            <LotItem key={index} lotObj={lotObj} />
                        ))}
                    </div> : <NoConnectionText />
                }
            </div>
        </PullToRefresh>
    );
}