import React from "react";
import { FaXmark } from "react-icons/fa6";

const Alert = ({ message, type }) => {
    let bg = "bg-red-100";
    let border = "border-red-400";
    let text = "text-red-700";

    if (type === "success") {
        bg = "bg-green-100";
        border = "border-green-400";
        text = "text-green-700";
    } else if (type === "warning") {
        bg = "bg-orange-100";
        border = "border-orange-400";
        text = "text-orange-600";
    }

    return (
        <div class={`${bg} border ${border} ${text} px-4 py-3 rounded-xl flex flex-row justify-between items-center`} role="alert">
            <div className="flex flex-col">
                <strong class="font-bold">Holy smokes!</strong>
                <span class="block sm:inline">{message}</span>
            </div>
            <FaXmark className={`${text} text-xl`} />
        </div>
    )
}

export default Alert;