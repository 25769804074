import React, { useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Toaster, toast } from 'sonner';
import { FaUserGraduate } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import UserPool from "../../UserPool.js";
import { logout } from '../utility/logout.js';
import ErrorBoundary from '../utility/ErrorBoundary.js';
import { Button } from '../utility/Button.js';
import { fetchAllData, postData } from '../../fetcher.js';

export const Profile = () => {
    const [userData, setUserData] = useState();
    const [saveText, setSaveText] = useState("Save");
    const [editedData, setEditedData] = useState({});
    const email = localStorage.getItem("email");
    const existingData = JSON.parse(sessionStorage.getItem("userData"));

    const storeData = async () => {
        try {
            // If the organization ID is not stored, fetch it
            const path = { "userData": `/general/spotUsers?email=${email}` };
            const data = await fetchAllData(path);
            const userData = data.userData;

            sessionStorage.setItem("userData", JSON.stringify(userData));
            setUserData(userData);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    // Handlers
    const handleEdit = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    // Check if the email is in email format
    const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
    };

    // If it's not in email format then throw an error toast
    const handleEmailBlur = () => {
        const inputEmail = editedData["email"].trim();
        if (validateEmail(inputEmail)) {
            return true;
        } else {
            toast.error("Invalid email address");
        }
    };

    // Fix label to be more readable
    const labelFixer = (label) => {
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    const handleSave = async () => {
        // Things that can't be empty when submitted
        const notEmptyList = ["firstName", "lastName", "email"];

        // Check if any of the not empty fields are empty
        let bool = true;
        while (bool === true) {
            for (let i in notEmptyList) {
                if (editedData[notEmptyList[i]] === "") {
                    toast.error(labelFixer(notEmptyList[i]) + " field cannot be empty.");
                }
            }
            bool = false;
        }

        // Update user attributes in AWS Cognito
        const cognitoUser = UserPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const attributes = [
                    new CognitoUserAttribute({ Name: "email", Value: editedData["email"] }),
                ];

                cognitoUser.updateAttributes(attributes, (err, result) => {
                    if (err) {
                        console.error("Failed to update attributes:", err);
                        toast.error("Failed to save settings.");
                    } else {
                        console.log("Successfully updated attributes:", result);
                        sessionStorage.setItem("userSettings", JSON.stringify(editedData));
                        setSaveText("Saved!");
                        toast.success("Settings saved successfully.");
                    }
                });
            });
        } else {
            toast.error("User not authenticated.");
        }

        userData.settings.personalInfo.firstName = editedData["firstName"];
        userData.settings.personalInfo.lastName = editedData["lastName"];

        const response = await postData("/general/spotUsers", userData);

        if (response) {
            toast.success("Settings saved successfully.");
        } else {
            toast.error("Failed to save settings.");
        }
        sessionStorage.setItem("userData", JSON.stringify(userData));

        setTimeout(() => {
            setSaveText("Save");
        }, 2000);
    };

    const handleLogOut = () => {
        logout();
        window.location.href = "/login";
    };

    // Get user data if data doesn't exist
    useEffect(() => {
        if (!existingData) {
            storeData();
        } else {
            setUserData(existingData);
        }
        // eslint-disable-next-line
    }, []);


    // Set and parse initial data
    useEffect(() => {
        if (!userData) {
            return;
        } else {
            const personalSettings = userData.settings.personalInfo;

            // Set initial data
            var initialData = {
                email: userData.email,
                firstName: personalSettings.firstName,
                lastName: personalSettings.lastName,
            };

            setEditedData(initialData);
        }
    }, [userData]);

    return (
        <div className='fcc'>
            <div className="mt-12 fcc">
                <h1 className='text-2xl font-bold dark:text-gray-300 text-left w-screen p-6'>Personal Information</h1>
                <ErrorBoundary>
                    <form className='fcc gap-4 w-screen px-12'>
                        <div className="flex flex-row justify-center w-full items-center gap-4">
                            <FaUserGraduate className='w-6 h-6 text-[#B7B7B7] dark:text-gray-300' />
                            <div className="flex flex-col w-full">
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal'>First Name</label>
                                <input
                                    name='firstName'
                                    type='name'
                                    value={editedData["firstName"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal mt-3'>Last Name: </label>
                                <input
                                    name='lastName'
                                    type='name'
                                    value={editedData["lastName"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row justify-center w-full items-center gap-4">
                            <MdEmail className='w-6 h-6 text-[#B7B7B7] dark:text-gray-300' />
                            <div className="flex flex-col w-full">
                                <label htmlFor="email" className='text-[#B7B7B7] dark:text-gray-300 text-sm font-normal mt-3'>Email: </label>
                                <input
                                    name='email'
                                    type="email"
                                    value={editedData["email"]}
                                    onChange={handleEdit}
                                    onBlur={handleEmailBlur}
                                    className="w-full inline-block dark:bg-transparent dark:text-gray-300 pb-1 text-sm font-normal h-6 border-b border-[#B7B7B7] rounded-none"
                                />
                            </div>
                        </div>
                        <div className="frc gap-2 w-full mt-6">
                            <Button text="Log Out" onClick={handleLogOut} />
                            <Button text={saveText} onClick={handleSave} color={"yellow"} />
                        </div>
                    </form>
                </ErrorBoundary>
            </div>
            <Toaster />
        </div>
    );
}