import React, { useState, useEffect, useMemo, startTransition } from "react";
import { Link } from "react-router-dom";
import ListItem from "./listItem";
import Header from "../lotView/Header";
import { useNavigate } from "react-router-dom";
import { NewViolation } from "../NewViolationButton";

export default function ListView() {
    const navigate = useNavigate();
    const [filterOption, setFilterOption] = useState("Time Parked (Descending)");
    const [sortedData, setSortedData] = useState([]);

    // Fetch lotData from sessionStorage and parse it
    const lotData = useMemo(() => {
        const data = sessionStorage.getItem("lotData");
        return data ? JSON.parse(data).items : [];
    }, []);

    // Handle item click
    const handleItemClick = (item) => {
        localStorage.setItem('violationData', JSON.stringify(item));
        navigate(`/violation/${item.licensePlate}`);
    };

    // Apply filter and sorting
    const applySorter = (data) => {
        return data.sort((a, b) => {
            switch (filterOption) {
                case "Time Parked (Ascending)":
                    return new Date(b.timeParked) - new Date(a.timeParked);
                case "Time Parked (Descending)":
                    return new Date(a.timeParked) - new Date(b.timeParked);
                case "Violation Type":
                    return a.ViolationType.localeCompare(b.violationType);
                case "Stall":
                    return a.Spot.localeCompare(b.Spot, undefined, { numeric: true });
                default:
                    return 0;
            }
        });
    };

    // Use useMemo to filter and sort data
    const validLotData = useMemo(() => {
        return lotData.filter(item => item.violationType !== "" && item.ticketWritten === false);
    }, [lotData]);

    useEffect(() => {
        startTransition(() => {
            const sorted = applySorter([...validLotData]);
            setSortedData(sorted);
        });
    }, [filterOption, validLotData]);

    const items = ["Time Parked (Ascending)", "Time Parked (Descending)", "Violation Type", "Stall"];

    var mt = "mt-16"
    const isNotIphone = !navigator.userAgent.match(/iPhone/i);
    if (isNotIphone) {
        mt = "mt-0"
    }

    const handleQuery = (query) => {
        const queryLower = query.toLowerCase();
        const filteredData = validLotData.filter((item) => {
            const licensePlate = item.licensePlate.toLowerCase();
            const violationType = item.violationType.toLowerCase();
            const lot = item.lot.toLowerCase();
            const spot = item.spot.toLowerCase();
            return licensePlate.includes(queryLower) || violationType.includes(queryLower) || lot.includes(queryLower) || spot.includes(queryLower);
        });
        const sorted = applySorter([...filteredData]);
        setSortedData(sorted);
    }

    return (
        <div className="fcc">
            <Header
                defaultSelection={"Time Parked (Descending)"}
                items={items}
                backButton={true}
                handleSort={setFilterOption}
                handleQuery={handleQuery}
                page={"listView"}
                onChange={(e) => setFilterOption(e.target.value)}
            />
            <div className={`w-[95%] ${mt}`}>
                {lotData === 0 && <div className="text-center text-2xl text-gray-500 mt-10">No violations found</div>}
                {sortedData.length === 0 ?
                    <div className="fcc gap-1">
                        <div className="w-full text-center font-rubik mt-10 text-xl">No violations found.</div>
                        <Link to={"/"} className="w-full text-center font-rubik underline">Back</Link>
                    </div> :
                    sortedData.map((item, index) => (
                        <div key={index}>
                            <ListItem
                                onClick={() => handleItemClick(item)}
                                spotID={item.spotID}
                                licensePlate={item.licensePlate}
                                violationType={item.violationType}
                                lot={item.lot}
                                spot={item.spot}
                                timeParked={item.timeParked}
                                coordinates={item.coordinates}
                                vehicleType={item.vehicleType}
                                permitNumber={item.permitNumber}
                            />
                        </div>
                    ))}
            </div>
            <NewViolation />
        </div>
    );
}
