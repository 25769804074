import React, { useState } from "react";
import UserPool from "../../UserPool";
import "./login.css";

export const SignUp = () => {
    // Initialize state variables
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents default refresh by the browser

        const attributeList = [{
            Name: 'email',
            Value: email 
        }];

        // Actual sign up process
        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.error(err);
                setErrorMessage(err.message);
            } else {
                window.location.href = "/dashboard";
                sessionStorage.setItem("isLoggedIn", true);
            }
        });
    }

    // Main tile
    const signUp = () => {
        return (
            <div className="sign-up-container">
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "0px", marginTop:"-10px" }}>Sign Up</h1>
                <p className="sign-up-paragraph">Please enter an email and a password. (at least 6 characters)</p>
                <p className="sign-up-confirm">Once finished, text me to confirm your account: <a style={{ textDecoration:"underline" }}href="tel:+15098285653"> (509) 828-5653</a></p>
                <div style={{ width:"75%" }}>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <div className="sign-up-title">
                                Email
                            </div>
                            <input
                                type="text"
                                value={email}
                                className="sign-up-input"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="sign-up-title">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                className="sign-up-input"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p style={{ color: "red", fontSize: "16px", marginTop: "5px", marginBottom:"-15px" }}>{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button className="sign-up-button" type="submit">Sign Up</button>
                    </form>
                    <div className="sign-up-already">
                        Already have an account? <a style={{ textDecoration:"underline", marginLeft:"5px" }}href="/login"> Login</a>
                    </div>
                </div>
            </div>
        )
    }

    // Fancy square yuh
    const square = (width, height, x, y, rotation) => {
        return (
            <div style={{
                position: "absolute",
                width: `${width*.75}%`,
                height: `${height*1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${y}vh`,
                left: `${x}vw`,
                zIndex: "0",
            }}></div>
        );
    }

    return (
        <div className="sign-up-parent-container">
            {signUp()}
            <div className="sign-up-square-div">
            {square(50, 50, 20, -25, 40)}
            {square(70, 70, 0, 80, -20)}
            {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}